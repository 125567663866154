import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { LOGIN } from 'api/auth'
import useIsSubmitButtonDisabled from 'hooks/useIsSubmitButtonDisabled'
import { AntdForm, SignInContainer, Title } from './styles'
import 'antd/dist/antd.css'
import { Form as FinalForm } from 'react-final-form'
import { Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/client'
import {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  setUserId
} from 'services/auth'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Alert } from 'antd'

export const SignInPage = () => {
  const { t } = useTranslation(['SignInPage', 'Error'])
  const history = useHistory()

  const [form, isDisabled, handleValuesChange] = useIsSubmitButtonDisabled([
    'email',
    'password'
  ])

  const [entered, setEntered] = useState(getAccessToken() != null)
  const [error, setError] = useState(false)
  const [login] = useMutation(LOGIN)

  const onSubmit = ({ email, password }) => {
    login({
      variables: {
        email,
        password
      }
    })
      .then(res => {
        const response = res?.data?.login
        if (!response) return
        setAccessToken(response.access_token)
        setRefreshToken(response.refresh_token)
        setUserId(response.id)

        if (response.access_token) {
          setError(false)
          setEntered(true)
        }
      })
      .catch(err => {
        if (err) message.error(t('InvalidCreds'))
        history.push('/login')
        setError(true)
      })
  }

  return (
    <SignInContainer>
      {!entered && (
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <AntdForm
              onFinish={handleSubmit}
              onFieldsChange={handleValuesChange}
              form={form}
              id="form"
            >
              {error && <Alert message={t('InvalidCreds')} type="error" />}
              <Title>{t('Title')}</Title>
              <Field name="email">
                {({ input: { onChange, value, name } }) => (
                  <Form.Item
                    name={name}
                    onChange={onChange}
                    value={value}
                    rules={[
                      { required: true, message: t('EmailInputRule') },
                      { type: 'email', message: t('EmailValideMessage') }
                    ]}
                  >
                    <Input name={name} placeholder="Email" size="large" />
                  </Form.Item>
                )}
              </Field>
              <Field name="password">
                {({ input: { onChange, value, name } }) => (
                  <Form.Item
                    name={name}
                    onChange={onChange}
                    value={value}
                    rules={[{ required: true, message: t('PassInputRule') }]}
                  >
                    <Input.Password
                      name={name}
                      placeholder="Password"
                      size="large"
                    />
                  </Form.Item>
                )}
              </Field>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="submitButton"
                  size="large"
                  disabled={isDisabled}
                >
                  {t('SubmitButton')}
                </Button>
              </Form.Item>
            </AntdForm>
          )}
        />
      )}
      {entered && <Redirect to="/" />}
    </SignInContainer>
  )
}
