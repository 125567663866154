import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { Observable } from '@apollo/client/core'
import { StatusCodes } from 'http-status-codes'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { split } from '@apollo/client'
import {
  getAccessToken,
  getRefreshToken,
  getUserId,
  setAccessToken,
  setRefreshToken
} from 'services/auth'
import { SignInPage } from 'views/SignInPage'
import NotFound from 'views/NotFound'
import Main from 'views/Main'
import './App.css'
import { message } from 'antd'

const resetToken = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    if (networkError && networkError.statusCode === StatusCodes.UNAUTHORIZED) {
      const myHeaders = new Headers()
      const urlencoded = new URLSearchParams()
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      }

      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
      urlencoded.append('id', `${getUserId()}`)
      urlencoded.append('refresh', `${getRefreshToken()}`)

      return new Observable(observer => {
        fetch(process.env.REACT_APP_REFRESH_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            setAccessToken(result.access_token)
            setRefreshToken(result.refresh_token)
            forward(operation).subscribe(observer)
          })
      })
    }

    if (networkError) {
      message.error(
        `[Network error]: ${networkError.message} (${networkError.statusCode})`
      )
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        if (graphQLError.extensions.exception.status === 401) return
        message.error(`[GraphQL error]: ${graphQLError.message}`)
      })
    }
  }
)

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken()
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }
  return forward(operation)
})

const httpLink = resetToken.concat(
  authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_API_URL_HTTP
    })
  )
)

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_URL_WS,
  options: {
    reconnect: true
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Staff: {
        fields: {
          staffsConnection: {
            merge(_, incoming) {
              return incoming
            }
          }
        }
      },
      Project: {
        fields: {
          projectConnection: {
            merge(_, incoming) {
              return incoming
            }
          }
        }
      }
    }
  }),
  link: splitLink
})

function App() {
  const Loader = () => (
    <div className="App">
      <div>loading...</div>
    </div>
  )

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (getAccessToken() ? <Main /> : <SignInPage />)}
              />
              <Route path="/login" exact component={SignInPage} />
              <Route path="*" component={NotFound} />
            </Switch>
          </BrowserRouter>
        </ApolloProvider>
      </div>
    </Suspense>
  )
}

export default App
