import React, { useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import {
  Employee,
  NameLine,
  EmployeeInfoContainer,
  EmployeeAvatar,
  StretchWrapper,
  SpecializationLine
} from './styles'
import Modal from 'components/Modal'
import EmployeeCard from 'components/EmployeeCard'
import EmptyUser from 'images/empty-user.svg'
import { STAFF_ITEM } from 'constants/dndTypes'
import { isEqual } from 'lodash'

function StaffItem(props) {
  const {
    projectId,
    employmentPercentage,
    staff: { firstName, lastName, photoURL, id, specialization },
    onDrop
  } = props

  const staffRef = useRef(props.staff)
  if (!isEqual(props.staff, staffRef.current)) {
    staffRef.current = props.staff
  }

  const [isOpen, setIsOpen] = useState(false)
  const [{ isDragging }, drag] = useDrag(() => ({
    type: STAFF_ITEM,
    item: { id },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end: async (item, monitor) => {
      if (!monitor.didDrop()) return
      const { projectId: destinationProject } = monitor.getDropResult()

      onDrop &&
        onDrop({
          sourceProject: projectId,
          destinationProject,
          staff: staffRef.current,
          employmentPercentage
        })
    }
  }))

  const handleOpenModal = () => {
    setIsOpen(!isOpen)
  }

  const Staff = (
    <>
      <StretchWrapper>
        <EmployeeAvatar photoURL={photoURL ? photoURL : EmptyUser} />
        {props.children}
      </StretchWrapper>
      <EmployeeInfoContainer>
        <NameLine>{firstName + ' ' + lastName}</NameLine>
        {specialization && (
          <SpecializationLine title={specialization}>
            {specialization}
          </SpecializationLine>
        )}
      </EmployeeInfoContainer>
    </>
  )

  return (
    <>
      <Employee ref={drag} onClick={handleOpenModal} $isDragging={isDragging}>
        {Staff}
      </Employee>

      <Modal showModal={isOpen} closeModal={handleOpenModal}>
        <EmployeeCard currentStaffId={id} closeModal={handleOpenModal} />
      </Modal>
    </>
  )
}

export default StaffItem
