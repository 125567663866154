import { USD } from 'constants/currency'

export function calcRateInCurrentCurrency(
  projectRate,
  dailyRate,
  currencyType
) {
  return currencyType === USD
    ? projectRate
    : +(projectRate / dailyRate).toFixed(0)
}

export function getCurrency() {
  return localStorage.getItem('currency')
}

export function setCurrency(newCurrency) {
  return localStorage.setItem('currency', newCurrency)
}
