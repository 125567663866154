import { Input } from 'antd'
import styled from 'styled-components'
import { white, lightGrey, grey } from 'theme/palette'
import { Statistic } from 'antd'
import { FULL_HD, WIDE_SCREEN } from 'constants/breakpoints'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 0.15rem;
  margin: 0.3rem 0.15rem 0;
  background-color: ${lightGrey};
  &:last-child {
    flex-grow: 1;
  }
`

export const ProjectAvatar = styled.div`
  background: url(${props => props.photoUrl}) no-repeat;
  width: 105px;
  height: 80px;
  margin-right: 10px;
  background-position: center;
  background-size: contain;
  border-radius: 10px;
  cursor: pointer;
  @media only screen and (min-width: ${FULL_HD}) {
    width: 5.6vw;
    height: 4.3vw;
  }
`

export const ProjectRate = styled(Statistic)`
  @media only screen and (max-width: ${WIDE_SCREEN}) {
    .ant-statistic-content {
      font-size: 1rem;
    }
  }
  @media only screen and (min-width: ${WIDE_SCREEN}) {
    .ant-statistic-content {
      font-size: 1.2vw;
    }
  }
`

export const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0.15rem 0.15rem 0.3rem;
  @media only screen and (min-width: ${FULL_HD}) {
    margin: 1% 1% 2%;
  }
`
export const Info = styled.div`
  width: 100%;
  text-align: start;
  cursor: pointer;
  color: #172b4d;
  margin: 3px auto auto 2px;
`

export const Button = styled.button`
  border: 1px solid ${grey};
  border-radius: 50%;
  padding: 0.8rem;
  line-height: 8px;
  cursor: pointer;
  outline: none;
  background-color: ${white};
`

export const ProjectName = styled.div`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  word-break: break-word;
  @media only screen and (min-width: ${FULL_HD}) {
    font-size: 1vw;
  }
`

export const Filter = styled(Input.Search)`
  margin: 5px 0px;
  input {
    letter-spacing: -1px;
    font-size: 0.8rem;
  }
`
